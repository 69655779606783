var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content full" },
    [
      _c("div", { staticClass: "content_box mt10" }, [
        _c(
          "div",
          { staticClass: "map_wrap", style: `height: 630px;` },
          [
            _c(
              "l-map",
              {
                staticStyle: { "z-index": "0" },
                attrs: {
                  zoom: _vm.zoom,
                  center: _vm.center,
                  options: _vm.mapOptions,
                  "world-copy-jump": "",
                },
              },
              [
                _c("l-tile-layer", {
                  attrs: {
                    url: _vm.url,
                    attribution: _vm.attribution,
                    "z-index": 900,
                  },
                }),
                _c("l-control", [
                  _c("img", {
                    staticStyle: { width: "150px" },
                    attrs: {
                      src: require("../../assets/images/schedule/kmtc_logo.gif"),
                    },
                  }),
                ]),
                _c("l-control", [
                  _c("img", {
                    staticStyle: { width: "150px" },
                    attrs: { src: "img/Legend.png" },
                  }),
                ]),
                _vm._l(_vm.markers, function (item, index) {
                  return [
                    _c("v-rotated-marker", {
                      key: `marker-${index}`,
                      attrs: {
                        "lat-lng": item.markerLatLng,
                        icon:
                          item.vslCd.substring(0, 1) == "J"
                            ? item.nvgSts == "0" || item.nvgSts == "8"
                              ? _vm.iconJ
                              : _vm.iconJS
                            : item.nvgSts == "0" || item.nvgSts == "8"
                            ? _vm.iconK
                            : _vm.iconKS,
                        "rotation-angle": item.rotationAngle,
                        options: { opacity: item.opacity },
                      },
                    }),
                  ]
                }),
                _vm._l(_vm.markers2, function (item, index) {
                  return [
                    item.markersIcon === "POD"
                      ? _c("v-rotated-marker", {
                          key: `marker2-${index}`,
                          attrs: {
                            "lat-lng": item.markerLatLng,
                            icon: _vm.iconP,
                            "rotation-angle": 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.fnFocusMarker(
                                item.markerLatLng,
                                index,
                                item.type
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                }),
                _vm._l(_vm.markers3, function (item, index) {
                  return [
                    index === _vm.markers3.length - 1 &&
                    _vm.markers.length === 0
                      ? _c("v-rotated-marker", {
                          key: `marker3-${index}`,
                          attrs: {
                            "lat-lng": item.markerLatLng,
                            icon:
                              item.vslCd.substring(0, 1) == "J"
                                ? item.nvgSts == "0" || item.nvgSts == "8"
                                  ? _vm.iconJ
                                  : _vm.iconJS
                                : item.nvgSts == "0" || item.nvgSts == "8"
                                ? _vm.iconK
                                : _vm.iconKS,
                            "rotation-angle": item.rotationAngle,
                          },
                        })
                      : _vm._e(),
                  ]
                }),
                _vm._l(_vm.markers4, function (item, index) {
                  return [
                    _c("v-rotated-marker", {
                      key: `marker4-${index}`,
                      attrs: {
                        "lat-lng": item.markerLatLng,
                        icon:
                          item.vslCd.substring(0, 1) == "J"
                            ? item.nvgSts == "0" || item.nvgSts == "8"
                              ? _vm.iconJ
                              : item.spdOvgGrnd >= 5
                              ? _vm.iconJ
                              : _vm.iconJS
                            : item.nvgSts == "0" || item.nvgSts == "8"
                            ? _vm.iconK
                            : item.spdOvgGrnd >= 5
                            ? _vm.iconK
                            : _vm.iconKS,
                        "rotation-angle": item.rotationAngle,
                      },
                    }),
                  ]
                }),
                _c("l-polyline", {
                  attrs: {
                    "lat-lngs": _vm.polylineN.latlngs,
                    color: _vm.polylineN.color,
                    "dash-array": _vm.polylineN.dashArray,
                    opacity: _vm.polylineN.opacity,
                    weight: _vm.polylineN.weight,
                  },
                }),
                _c("l-polyline", {
                  attrs: {
                    "lat-lngs": _vm.polyline.latlngs,
                    color: _vm.polyline.color,
                    opacity: _vm.polyline.opacity,
                    weight: _vm.polyline.weight,
                  },
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "win-layer-pop",
        { staticClass: "cargo-vessel-location-popup" },
        [
          _vm.popupComponent
            ? _c(_vm.popupComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popupParams },
                on: { close: _vm.closePopup },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }