<template>
  <div class="content full">
    <div class="content_box mt10">
      <div :style="`height: 630px;`" class="map_wrap">
        <l-map
          :zoom="zoom"
          :center="center"
          :options="mapOptions"
          world-copy-jump
          style="z-index: 0;"
        >
          <l-tile-layer
            :url="url"
            :attribution="attribution"
            :z-index="900"
          />
          <l-control>
            <img src="../../assets/images/schedule/kmtc_logo.gif" style="width: 150px;">
          </l-control>
          <l-control>
            <img src="img/Legend.png" style="width: 150px;">
          </l-control>
          <template v-for="(item, index) in markers">
            <v-rotated-marker
              :key="`marker-${index}`"
              :lat-lng="item.markerLatLng"
              :icon="item.vslCd.substring(0,1)== 'J' ? (item.nvgSts=='0') || (item.nvgSts=='8') ? iconJ : iconJS : (item.nvgSts=='0') || (item.nvgSts=='8') ? iconK : iconKS"
              :rotation-angle="item.rotationAngle"
              :options="{opacity:item.opacity}"
            >
            </v-rotated-marker>
          </template>
          <template v-for="(item, index) in markers2">
            <v-rotated-marker
              v-if="item.markersIcon === 'POD'"
              :key="`marker2-${index}`"
              :lat-lng="item.markerLatLng"
              :icon=" iconP"
              :rotation-angle="0"
              @click="fnFocusMarker(item.markerLatLng, index,item.type)"
            >
            </v-rotated-marker>
          </template>
          <template v-for="(item, index) in markers3">
            <v-rotated-marker
              v-if="index === (markers3.length-1) && markers.length === 0"
              :key="`marker3-${index}`"
              :lat-lng="item.markerLatLng"
              :icon="item.vslCd.substring(0, 1) == 'J' ? (item.nvgSts == '0') || (item.nvgSts == '8') ? iconJ : iconJS : (item.nvgSts == '0') || (item.nvgSts == '8') ? iconK : iconKS"
              :rotation-angle="item.rotationAngle"
            >
              <!-- <l-tooltip>
                Location : {{ item.markerLatLng.lat }}, {{ item.markerLatLng.lng }} <br />
                dtm : {{ item.cntrDtm }}
              </l-tooltip> -->
            </v-rotated-marker>
          </template>
          <template v-for="(item, index) in markers4">
            <v-rotated-marker
              :key="`marker4-${index}`"
              :lat-lng="item.markerLatLng"
              :icon="item.vslCd.substring(0, 1) == 'J' ? (item.nvgSts == '0') || (item.nvgSts == '8') ? iconJ : item.spdOvgGrnd >= 5 ? iconJ : iconJS : (item.nvgSts == '0') || (item.nvgSts == '8') ? iconK : item.spdOvgGrnd >= 5? iconK : iconKS"
              :rotation-angle="item.rotationAngle"
            >
              <!-- <l-tooltip>
                Location : {{ item.markerLatLng.lat }}, {{ item.markerLatLng.lng }} <br />
                dtm : {{ item.cntrDtm }}
                vslCd : {{ item.vslCd }}
                voyNo : {{ item.voyNo }}
                nvgSts : {{ item.nvgSts }}
                spdOvgGrnd : {{ item.spdOvgGrnd }}
              </l-tooltip> -->
            </v-rotated-marker>
          </template>
          <l-polyline :lat-lngs="polylineN.latlngs" :color="polylineN.color" :dash-array="polylineN.dashArray" :opacity="polylineN.opacity" :weight="polylineN.weight"></l-polyline>
          <l-polyline :lat-lngs="polyline.latlngs" :color="polyline.color" :opacity="polyline.opacity" :weight="polyline.weight"></l-polyline>
        </l-map>
      </div>
    </div>
    <win-layer-pop class="cargo-vessel-location-popup">
      <component
        v-if="popupComponent"
        :is="popupComponent"
        @close="closePopup"
        :parent-info="popupParams"
      />
    </win-layer-pop>
  </div>
</template>

<script>

import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo2'
import rfDg from '@/api/rest/trans/rfDg'
import cargo from '@/api/rest/trans/cargo'

import { latLng, icon } from 'leaflet'
import { LMap, LTileLayer, LMarker, LControl, LTooltip, LPopup, LPolyline } from 'vue2-leaflet'
import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker'
import 'leaflet/dist/leaflet.css'

export default {
  name: 'CargoVesselLocationInfo',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    LTooltip,
    LPopup,
    LPolyline,
    'v-rotated-marker': Vue2LeafletRotatedMarker,
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    EAutoCompleteVessel2: () => import('@/components/common/EAutoCompleteVessel2'),
    DigitalTransform: () => import('@/pages/trans/DigitalTransform'),
    CustomerService: () => import('@/pages/trans/CustomerService'),
    ItPlanning: () => import('@/pages/trans/ItPlanning'),
    DataAnalysis: () => import('@/pages/trans/DataAnalysis'),
    LinerOperation: () => import('@/pages/trans/LinerOperation'),
    CargoPortInfoPop: () => import('@/pages/trans/popup/CargoPortInfoPop')
  },
  props: {
    selectCntr: {
      type: Array,
      default: function () {
        return {
        }
      }
    },
    allVesselLocationInfo: {
      type: Array,
      default: function () {
        return {
        }
      }
    },
    allPortLocationInfo: {
      type: Array,
      default: function () {
        return {
        }
      }
    },
    vslLocation: {
      type: Array,
      default: function () {
        return {
        }
      }
    }
  },

  data: function () {
    return {
      popupComponent: null,
      popupCallback: null,
      popupParams: {},

      itemDataE: {
        item1: { valA: 10, valB: 40, rate: 25, name: 'POD' },
        item2: { valA: 10, valB: 40, rate: 25, name: 'What you want to see' }
      },
      portMarkerA: [{
          tableVslCdA: '', tableVoyNoA: '', tableTrmlCdA: '', tableSchEta: ''
      }],
      portMarkerB: [{
          tableVslCdB: '', tableVoyNoB: '', tableTrmlCdB: '', tableSchEtd: ''
      }],
      portMarkerC: [{
          tableVslCdC: '', tableVoyNoC: '', tableAisEta: ''
      }],
      portMarkerD: [{
          tableVslCdD: '', tableVoyNoD: ''
      }],
      portMarker: [{
          tableVslCdA: '', tableVoyNoA: '', tableTrmlCdA: '', tableSchEta: '', tableVslCdB: '', tableVoyNoB: '', tableTrmlCdB: '', tableSchEtd: '', tableVslCdC: '', tableVoyNoC: '', tableAisEta: '', tableVslCdD: '', tableVoyNoD: ''
      }],
      focusPort: [
        { name: '국가', ctrCd: '' },
        { name: '항구 명칭', portNm: '' },
        { name: '주소', cyAddr: '' },
        { name: '웹사이트', hmpgUrlAddr: '' }
      ],
      focusVessel: [
        { name: '선명/항차', vslCd_voyNo: '' },
        { name: '선박 이름', vslNm: '' },
        { name: '선박 상태', nvgSts: '' },
        { name: 'AIS ETA', aisEta: '' },
        { name: 'AIS DEST', aisDest: '' },
        { name: 'SPEED', spd: '' },
        { name: '서비스 루트', rteCd: '' }
      ],
      vslCd: undefined,

      vslNm: undefined,

      mapHeight: 500,

      // 지도 확대정도
      zoom: 5,
      // 지도 중앙 위/경도(default - 서울)
      center: latLng(37.564214, 127.001699),
      // 지도 호출 url
      url: 'https://api.maptiler.com/maps/openstreetmap/256/{z}/{x}/{y}.jpg?key=DiDJ0YR81yQKSEioZPoD',
      // 우측 하단 표시
      attribution: '<a href="https://www.kmtc.co.kr/" target="_blank">&copy; KMTC</a> <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
      // 지도 옵션
      mapOptions: {
        zoomSnap: 0.5,
        minZoom: 2
      },
      // 아이콘 옵션
      iconP: icon({
        iconUrl: '/img/port3.png',
        iconSize: [12, 12]
      }),
      iconTB: icon({
        iconUrl: '/img/typhoon_blue.gif',
        iconSize: [30, 30]
      }),
      iconTR: icon({
        iconUrl: '/img/typhoon_red.gif',
        iconSize: [30, 30]
      }),
      iconDot: icon({
        iconUrl: '/img/iconDot.png',
        iconSize: [10, 10]
      }),
      iconK: icon({
        iconUrl: '/img/blue_ship.png',
        iconSize: [20, 20]
      }),
      iconKS: icon({
        iconUrl: '/img/blue_ankle.png',
        iconSize: [20, 20]
      }),
      iconJ: icon({
        iconUrl: '/img/orangered_ship.png',
        iconSize: [20, 20]
      }),
      iconJS: icon({
        iconUrl: '/img/orangered_ankle.png',
        iconSize: [20, 20]
      }),
      markers: [
        {
          opacity: 1.0,
          type: 'V',
          markerLatLng: latLng(37.564214, 127.001699),
          rotationAngle: 0,
          vslNm: 'N/A',
          aisRecvDtm: 'N/A',
          vslCd: 'N/A',
          spd: 'N/A',
          voyNo: 'N/A',
          rteCd: 'N/A'
        }
      ],
      markers2: [
        {
          type: 'P',
          markerLatLng: latLng(37.564214, 127.001699),
          ctrCd: 'N/A',
          plcCd: 'N/A',
          portNm: 'N/A'
        }
      ],
      markers3: [],
      markers4: [],
      itemOrder: [
        'item1',
        'item2',
        'item3',
        'item4'
      ],
      nvg: {
        0: 'Under way using engine',
        1: 'At anchor',
        2: 'Not under command',
        3: 'Restricted manoeuverability',
        4: 'Constrained by her draught',
        5: 'Moored',
        6: 'Aground',
        7: 'Engaged in Fishing',
        8: 'Under way sailing',
        9: 'Reserved for future amendment of Navigational Status for HSC',
        10: 'Reserved for future amendment of Navigational Status for WIG',
        11: 'Reserved for future use',
        12: 'Reserved for future use',
        13: 'Reserved for future use',
        14: 'AIS-SART is active',
        15: 'Not defined (default)'
        },
      regParams: {
        nvgType: 'A',
        vslType: 'A',
        Type: true,
        rteCd: 'A'
      },
      Top5: [],
      polyline: {
        latlngs: [],
        color: 'indigo',
        opacity: 0.6,
        weight: 3
      },
      polylineN: {
        latlngs: [],
        color: 'gray',
        dashArray: '1, 5',
        opacity: 1,
        weight: 2
      },
      minIndex: 0,
      // vslLocation: [],
      arrPodArrived: [],
      arrPolArrived: [],
      arrArrivedExc: [],
      arrNvgStsArrived: []
    }
  },
  computed: {
    solVariable () {
      return {
        '--barHeight1': this.Top5[0][1],
        '--barHeight2': this.Top5[1][1],
        '--barHeight3': this.Top5[2][1],
        '--barHeight4': this.Top5[3][1],
        '--barHeight5': this.Top5[4][1]
      }
    },
    cargoSteps () {
      return this.$store.getters['trans/getCargoSteps']
    },
    tsList () {
      return this.$store.getters['trans/getTsList']
    },
    tsInfo () {
      return this.$store.getters['trans/getTsInfo']
    },
    cargoDetailList () {
      return this.$store.getters['trans/getCargoDetailList']
    }
  },
  watch: {},
  created () {
    this.mapHeight = $('body').height() - 200
    if (this.mapHeight < 500) {
      this.mapHeight = 500
    }

    this.getLocationToMap()
  },
  methods: {
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: '확인',
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    async getLocationToMap () {
      this.markers2 = []

      // 선박 위치 API 호출
      const TH = this
      this.markers2 = []

      // await vslScheduleInfo.getAllVslLocationInfo()
      //   .then(response => {
      //     if (response.status === 200) {
            // 선박 위치 API 호출
            // const resData = response.data
            const allVesselLocationInfo = this.allVesselLocationInfo

          if (allVesselLocationInfo !== undefined && allVesselLocationInfo.length > 0) {
            this.markers = []

            allVesselLocationInfo.forEach(vesselLocationInfo => {
              const lttd = vesselLocationInfo.lttd
              const lngtd = vesselLocationInfo.lngtd
              const vslCd = vesselLocationInfo.vslCd
              const nvgSts = vesselLocationInfo.nvgSts
              const rteCd = vesselLocationInfo.rteCd

              if (this.$ekmtcCommon.isNotEmpty(lttd) && this.$ekmtcCommon.isNotEmpty(lngtd)) {
                let aisEta = 'N/A'
                let aisRecvDtm = 'N/A'
                if (this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.aisEta) && vesselLocationInfo.aisEta.length >= 8) {
                  aisEta = `${vesselLocationInfo.aisEta.substring(0, 2)}/${vesselLocationInfo.aisEta.substring(2, 4)} ${vesselLocationInfo.aisEta.substring(4, 6)}:${vesselLocationInfo.aisEta.substring(6, 8)}`
                }
                if (this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.aisRecvDtm) && vesselLocationInfo.aisRecvDtm.length >= 14) {
                  aisRecvDtm = `${vesselLocationInfo.aisRecvDtm.substring(0, 4)}/${vesselLocationInfo.aisRecvDtm.substring(4, 6)}/${vesselLocationInfo.aisRecvDtm.substring(6, 8)} ${vesselLocationInfo.aisRecvDtm.substring(8, 10)}:${vesselLocationInfo.aisRecvDtm.substring(10, 12)}:${vesselLocationInfo.aisRecvDtm.substring(12, 14)}`
                }

                let today = new Date()
                let year = today.getFullYear() //연도
                let month = ('0' + (today.getMonth() + 1)).slice(-2) //월
                let date = ('0' + today.getDate()).slice(-2) //날짜
                let hour = ('0' + today.getHours()).slice(-2) //시간
                let time = ('0' + today.getMinutes()).slice(-2) //시간
                let now = year + month + date + hour + time

                  this.vslLocation.forEach(item => {
                    if (item.rteCd === vesselLocationInfo.rteCd && item.vslCd === vesselLocationInfo.vslCd) {
                      let aisRecvDtm = vesselLocationInfo.aisRecvDtm

                      // 운행중인 선박
                      if (Number(item.eta + '00') >= Number(aisRecvDtm) && Number(item.etd) <= Number(now)) {
                          this.markers.push({
                            type: 'V',
                            markerLatLng: latLng(lttd, lngtd),
                            rotationAngle: this.$ekmtcCommon.isEmpty(vesselLocationInfo.truHdg) || Number(vesselLocationInfo.truHdg) === 511 ? 0 : Number(vesselLocationInfo.truHdg),
                            vslNm: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.vslNm) ? vesselLocationInfo.vslNm : 'N/A',
                            spdOvgGrnd: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.spdOvgGrnd) ? vesselLocationInfo.spdOvgGrnd : 'N/A',
                            aisEta: aisEta,
                            aisDest: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.aisDest) ? vesselLocationInfo.aisDest : 'N/A',
                            aisRecvDtm: aisRecvDtm,
                            vslCd: vslCd,
                            nvgSts: nvgSts,
                            voyNo: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.voyNo) ? vesselLocationInfo.voyNo : 'N/A',
                            rteCd: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.rteCd) ? vesselLocationInfo.rteCd : 'N/A',
                            oprUno: this.$ekmtcCommon.isNotEmpty(vesselLocationInfo.oprUno) ? vesselLocationInfo.oprUno : 'N/A'
                          })
                      }
                    }
                  })
              }
            })
          }
      //     }
      // }).catch(error => {
      //     console.log(error)
      // })

      await vslScheduleInfo.getAllPortLocationInfo()
        .then(response => {
          if (response.status === 200) {
            const resData = response.data
            const allPortLocationInfo = resData.allPortLocationInfo

            if (allPortLocationInfo !== undefined && allPortLocationInfo.length > 0) {
              this.markers2 = []

              // console.log('allPortLocationInfo @@@@@ ', allPortLocationInfo)

              allPortLocationInfo.forEach(portLocationInfo => {
                const lttd = portLocationInfo.lttd
                const lngtd = portLocationInfo.lngtd
                const ctrCd = portLocationInfo.ctrCd
                const plcCd = portLocationInfo.plcCd
                const portNm = portLocationInfo.portNm
                let trmlCd

                for (var item of this.cargoDetailList) {
                  if (ctrCd === item.ctrCd && plcCd === item.plcCd) {
                    trmlCd = item.trmlCd
                  }
                }

                this.markers2.push({
                    type: 'P',
                    markerLatLng: latLng(lttd, lngtd),
                    ctrCd: ctrCd,
                    plcCd: plcCd,
                    portNm: portNm,
                    trmlCd: trmlCd
                })
              })
            }
          }
        })
        .catch(error => {
          console.log(error)
        })

      if (this.markers.length > 0) {
        await this.markers.forEach((marker, idx) => {
            this.fnFocusMarker(idx, 'V')
        })
      } else {
        await this.fnFocusMarker('', 'V')
      }
    },
    async fnFocusMarker (index, type) {
      if (type === 'P') {
        const data = {
          portCd: this.markers2[index].plcCd
        }
        this.regParams.Type = false
        this.focusPort[0].ctrCd = this.markers2[index].portNm.split(',')[1]
        this.focusPort[1].portNm = this.markers2[index].portNm.split(',')[0]

        const params = {
          portCd: this.markers2[index].plcCd,
          trmlCd: this.markers2[index].trmlCd
        }

        await cargo.getPortDetailInfo(params)
          .then(response => {
            // console.log('response @@@@ ', response)
            const data = response.data
            this.focusPort[2].cyAddr = data.cyAddr
            this.focusPort[3].hmpgUrlAddr = data.hmpgUrlAddr
          })

        await vslScheduleInfo.getPortDetailETA(data)
          .then(response => {
            if (response.status === 200) {
              const resData = response.data
              const portDetailETAInfo = resData.portDetailETAInfo
              this.portMarkerA = []
              if (portDetailETAInfo !== undefined && portDetailETAInfo.length > 0) {
                portDetailETAInfo.forEach(portDetailETAInfo => {
                  const vslCd = portDetailETAInfo.vslCd
                  const voyNo = portDetailETAInfo.voyNo
                  const trmlCd = portDetailETAInfo.trmlCd
                  const rvsdEta = portDetailETAInfo.rvsdEta

                  this.portMarkerA.push({
                    tableVslCdA: vslCd,
                    tableVoyNoA: voyNo,
                    tableTrmlCdA: trmlCd,
                    tableSchEta: rvsdEta
                      })
                    })
                    }
                  }
                })
          .catch(error => {
            console.log(error)
          })
        await vslScheduleInfo.getPortDetailETD(data)
        .then(response => {
          if (response.status === 200) {
            const resData = response.data
            const portDetailETDInfo = resData.portDetailETDInfo
            this.portMarkerB = []
            if (portDetailETDInfo !== undefined && portDetailETDInfo.length > 0) {
              portDetailETDInfo.forEach(portDetailETDInfo => {
                const vslCd = portDetailETDInfo.vslCd
                const voyNo = portDetailETDInfo.dpoVoyNo
                const trmlCd = portDetailETDInfo.trmlCd
                const rvsdEtd = portDetailETDInfo.rvsdEtd

                this.portMarkerB.push({
                  tableVslCdB: vslCd,
                  tableVoyNoB: voyNo,
                  tableTrmlCdB: trmlCd,
                  tableSchEtd: rvsdEtd
                    })
                  })
                  }
                }
              })
        .catch(error => {
          console.log(error)
        })
        await vslScheduleInfo.getPortAisETA(data)
        .then(response => {
          if (response.status === 200) {
            const resData = response.data
            const portAisETAInfo = resData.portAisETAInfo
            this.portMarkerC = []
            if (portAisETAInfo !== undefined && portAisETAInfo.length > 0) {
              portAisETAInfo.forEach(portAisETAInfo => {
                const vslCd = portAisETAInfo.vslCd
                const voyNo = portAisETAInfo.voyNo
                const aisEta = portAisETAInfo.aisEta

                this.portMarkerC.push({
                  tableVslCdC: vslCd,
                  tableVoyNoC: voyNo,
                  tableAisEta: aisEta
                    })
                  })
                  }
                }
              })
        .catch(error => {
          console.log(error)
        })
        await vslScheduleInfo.getPortAisETD(data)
        .then(response => {
          if (response.status === 200) {
            const resData = response.data
            const portAisETDInfo = resData.portAisETDInfo
            this.portMarkerD = []
            if (portAisETDInfo !== undefined && portAisETDInfo.length > 0) {
              portAisETDInfo.forEach(portAisETDInfo => {
                const vslCd = portAisETDInfo.vslCd
                const voyNo = portAisETDInfo.voyNo

                this.portMarkerD.push({
                  tableVslCdD: vslCd,
                  tableVoyNoD: voyNo
                    })
                  })
                  }
                }
              })
        .catch(error => {
          console.log(error)
        })
        const lengthEta = this.portMarkerA.length
        const lengthEtd = this.portMarkerB.length
        const lengthAisEta = this.portMarkerC.length
        const lengthAisEtd = this.portMarkerD.length
        const tmpArr = [lengthEta, lengthEtd, lengthAisEta, lengthAisEtd]
        const Max = Math.max.apply(null, tmpArr)
        this.portMarker = []
        for (var i = 0; i < Max; i++) {
          this.portMarker.push({
            tableVslCdA: this.portMarkerA[i] !== undefined ? this.portMarkerA[i].tableVslCdA : '',
            tableVoyNoA: this.portMarkerA[i] !== undefined ? this.portMarkerA[i].tableVoyNoA : '',
            tableTrmlCdA: this.portMarkerA[i] !== undefined ? this.portMarkerA[i].tableTrmlCdA : '',
            tableSchEta: this.portMarkerA[i] !== undefined ? this.portMarkerA[i].tableSchEta : '',
            tableVslCdB: this.portMarkerB[i] !== undefined ? this.portMarkerB[i].tableVslCdB : '',
            tableVoyNoB: this.portMarkerB[i] !== undefined ? this.portMarkerB[i].tableVoyNoB : '',
            tableTrmlCdB: this.portMarkerB[i] !== undefined ? this.portMarkerB[i].tableTrmlCdB : '',
            tableSchEtd: this.portMarkerB[i] !== undefined ? this.portMarkerB[i].tableSchEtd : '',
            tableVslCdC: this.portMarkerC[i] !== undefined ? this.portMarkerC[i].tableVslCdC : '',
            tableVoyNoC: this.portMarkerC[i] !== undefined ? this.portMarkerC[i].tableVoyNoC : '',
            tableAisEta: this.portMarkerC[i] !== undefined ? this.portMarkerC[i].tableAisEta : '',
            tableVslCdD: this.portMarkerD[i] !== undefined ? this.portMarkerD[i].tableVslCdD : '',
            tableVoyNoD: this.portMarkerD[i] !== undefined ? this.portMarkerD[i].tableVoyNoD : ''
          })
        }
        const popupParam = {
          portMarker: this.portMarker,
          focusPort: this.focusPort
        }
        this.openPopup('CargoPortInfoPop', popupParam)
      } else if (type === 'V') {
        // 운행중인 선박 일때
        if (index !== '') {
          this.regParams.Type = true
          this.portMarkerA = []
          this.portMarkerB = []
          this.portMarkerC = []
          this.portMarkerD = []
          this.portMarker = []
          this.vslCd = this.markers[index].vslCd
          this.center = this.markers[index].markerLatLng
          this.focusVessel.markerLatLng = this.center
          this.focusVessel[0].vslCd_voyNo = this.markers[index].vslCd + '/' + this.markers[index].voyNo
          this.focusVessel[1].vslNm = this.markers[index].vslNm
          this.focusVessel[2].nvgSts = this.markers[index].nvgSts
          this.focusVessel[3].aisEta = this.markers[index].aisEta
          this.focusVessel[4].aisDest = this.markers[index].aisDest
          this.focusVessel[5].spd = this.markers[index].spdOvgGrnd
          this.focusVessel[6].rteCd = this.markers[index].rteCd
        }

        this.markers3 = []

        let data = {
          vslCd: '',
          rteCd: '',
          voyNo: '',
          portCd: '',
          drctn: ''
        }

        let tmp = []

        // 날짜/시간 비교를 위한 현재 날짜/시간 구하기
        let today = new Date()
        let year = today.getFullYear() //연도
        let month = ('0' + (today.getMonth() + 1)).slice(-2) //월
        let date = ('0' + today.getDate()).slice(-2) //날짜
        let hour = ('0' + today.getHours()).slice(-2) //시간
        let time = ('0' + today.getMinutes()).slice(-2) //시간
        var now = year + month + date + hour + time
        let sortArr = []
        let markers2LatLng

        // 도착후 POD 항구 정보 넣기
        this.markers2.forEach((m2Item, m2Idx) => {
          if (m2Item.plcCd === this.selectCntr[0].podPortCd || m2Item.plcCd === this.tsInfo.plcCd) {
            m2Item.markersIcon = 'POD'
          }
        })

        this.polyline.latlngs = []
        this.markers4 = []

        let polyline = {
          latlngs: [],
          color: 'indigo',
          opacity: 0.6,
          weight: 3
        }

        let polylineN = {
          latlngs: [],
          color: 'gray',
          dashArray: '1, 5',
          opacity: 1,
          weight: 2
        }

        let markers4 = []
        const vslLocation = this.vslLocation

        // 화물추적 정보로 AIS 데이터 가공
        for (let item of vslLocation) {
          let arrPodArrived = []
          let arrArrivedExc = []
          this.arrNvgStsArrived = []
          this.arrPolArrived = []
          this.arrPodArrived = []
          this.arrArrivedExc = []

          const vesselPreiousRoute = item.vesselPreiousRoute

          tmp = 'N/A'

          await vesselPreiousRoute.forEach((pItem, idx) => {
            for (let i = 191; i <= this.markers2.length - 1; i++) {
              var ais_dest = pItem.aisDest
              ais_dest = ais_dest.replace(/ /g, '')

              if (ais_dest.indexOf('OFF') === -1) {
                if (ais_dest.indexOf('_') > -1) {
                  ais_dest = ais_dest.replaceAll('_', ' ').substring(3, 6)
                } else if (ais_dest.indexOf('-') > -1) {
                  ais_dest = ais_dest.replaceAll('-', '')
                } else {
                  if (ais_dest.indexOf('PUS') > -1) { // 부산 포트이름 특이케이스
                    ais_dest = 'PUS'
                  } else {
                    ais_dest = ais_dest.substring(ais_dest.indexOf('>') + 1, ais_dest.length + 1)
                  }
                }

                if (ais_dest === this.markers2[i].ctrCd) {
                  tmp = this.markers2[i].plcCd
                  pItem.aisDest = tmp
                }
              }
            }

            // AIS,ICC port Cd 데이터 다름
            if (pItem.aisDest === 'PKG') {
              pItem.aisDest = 'PKW'
            }

            // 출발지 ETD 계산값
            if (pItem.spdOvgGrnd <= 5 && (pItem.nvgSts === '5' || pItem.nvgSts === '1')) {
              this.arrNvgStsArrived.push(pItem)
            }

            // 출발지 ETD 계산값
            if (pItem.aisDest === item.polPortCd) {
              this.arrPolArrived.push(pItem)
            }

            // 도착지 ETA 계산값
            if (pItem.aisDest === item.podPortCd) {
              arrPodArrived.push(pItem)
              this.arrPodArrived.push(pItem)
            }
          })

          // ETD, ETA 계산 : nvgSts 기준으로 eta 계산
          let arrNvgStsArrived = this.arrNvgStsArrived
          let nvgStsEta = []
          let nvgStsEtd = []

          // 도착후 선박 ETD
          if (this.arrNvgStsArrived.length > 0) {
            arrNvgStsArrived = this.arrNvgStsArrived
          } else {
            arrNvgStsArrived = this.arrPolArrived
          }

          for (var etd of arrNvgStsArrived) {
            if (etd.aisDest === item.polPortCd) {
              nvgStsEtd.push(etd)
            }
          }

          for (var eta of arrNvgStsArrived) {
            if (eta.aisDest === item.podPortCd) {
              nvgStsEta.push(eta)
            }
          }

          if (nvgStsEtd.length === 0) {
            nvgStsEtd = this.arrPolArrived
          }

          if (nvgStsEta.length === 0) {
            nvgStsEta = this.arrPodArrived
          }

          await vesselPreiousRoute.forEach((pItem, idx) => {
            const lttd = Number(pItem.lttd)
            const lngtd = Number(pItem.lngtd)
            var ais_dest = pItem.aisDest

            // 도착후 POD 항구 정보 넣기
            this.markers2.forEach((m2Item, m2Idx) => {
              if (m2Item.plcCd === item.podPortCd) {
                m2Item.markersIcon = 'POD'

                markers2LatLng = m2Idx

                if (this.center.lat === 37.564214) {
                  this.center = m2Item.markerLatLng
                }
              }
            })

            let etaTime, etdTime

            if (Number(item.eta) < Number(now)) { // 도착 후
              // console.log('도착 후 @@@@@')

              // 도착 후 시간 계산
              // eta, etd 시간 변환
              let aisEta, etaDt, etaTm, etaDtm, covEta

              if (arrPodArrived.length > 0) {
                // aisDest +36 시간 계산
                aisEta = year + arrPodArrived[arrPodArrived.length - 1].aisEta
                etaDt = moment(aisEta.substring(0, 8)).format('YYYY-MM-DD')
                etaTm = aisEta.substring(8, 10) + ':' + aisEta.substring(10, 12) + ':' + '00'
                etaDtm = etaDt + ' ' + etaTm
                covEta = new Date(etaDtm)

                covEta = covEta.getTime() + (36 * 60 * 60 * 1000)
                covEta = new Date(covEta)
                covEta = moment(covEta).format('YYYYMMDDHHMMSS')
              }

              // ETD, ETA 계산값
              etdTime = nvgStsEtd.length > 0 ? nvgStsEtd[0].aisRecvDtm : arrNvgStsArrived[0].aisRecvDtm
              etaTime = nvgStsEta.length > 0 ? nvgStsEta[nvgStsEta.length - 1].aisRecvDtm : arrNvgStsArrived[arrNvgStsArrived.length - 1].aisRecvDtm

              if (etdTime <= Number(pItem.aisRecvDtm) && etaTime >= Number(pItem.aisRecvDtm) && '0,1,5,8'.includes(pItem.nvgSts) && lngtd >= 45) {
                  polyline.latlngs.push(latLng(lttd, lngtd))

                  if (idx % 12 === 0 || idx === (vesselPreiousRoute.length - 1)) {
                    markers4.push({
                      opacity: 1.0,
                      markerLatLng: latLng(lttd, lngtd),
                      rotationAngle: Number(pItem.truHdg),
                      vslNm: 'N/A',
                      vslCd: pItem.vslCd,
                      voyNo: pItem.voyNo,
                      rteCd: 'N/A',
                      nvgSts: pItem.nvgSts,
                      cntrDtm: pItem.aisRecvDtm,
                      markersIcon: '',
                      spdOvgGrnd: pItem.spdOvgGrnd
                    })
                  }

                  sortArr.push({
                    idx,
                    aisRecvDtm: pItem.aisRecvDtm
                  })
                }
              } else {
                // console.log('@@@@@ 운행중 IF tmp @@@@@ ', tmp)
                // 운행중 시간 계산

                // ETD 계산값
                etdTime = nvgStsEtd.length > 0 ? nvgStsEtd[0].aisRecvDtm : arrNvgStsArrived[0].aisRecvDtm

                if (Number(etdTime) <= Number(pItem.aisRecvDtm) && '0,1,5,8'.includes(pItem.nvgSts) && lngtd >= 45) { // 2023.02.23 선박명,항차 모두 일치하는 운행중인 Ais ETD 기준으로 데이터 제외
                  if (ais_dest === tmp) {
                    polyline.latlngs.push(latLng(lttd, lngtd))

                    if (idx % 12 === 0 || idx === (vesselPreiousRoute.length - 1)) {
                      markers4.push({
                        opacity: 1.0,
                        markerLatLng: latLng(lttd, lngtd),
                        rotationAngle: Number(pItem.truHdg),
                        vslNm: 'N/A',
                        vslCd: pItem.vslCd,
                        voyNo: pItem.voyNo,
                        rteCd: 'N/A',
                        nvgSts: pItem.nvgSts,
                        cntrDtm: pItem.aisRecvDtm,
                        markersIcon: '',
                        spdOvgGrnd: pItem.spdOvgGrnd
                      })
                    }

                    sortArr.push({
                      idx,
                      aisRecvDtm: pItem.aisRecvDtm
                    })

                    // 도착전 AIS_DEST 항구 정보 넣기
                    this.markers2.forEach((item, idx) => {
                      if (ais_dest === item.plcCd) {
                        item.markersIcon = 'POD'
                      }
                    })
                  } else {
                    // 운행중인 선박의 AIS_DEST 이전 데이터 출력
                    if (Number(etdTime) <= Number(pItem.aisRecvDtm) && '0,1,5,8'.includes(pItem.nvgSts) && lngtd >= 45) {
                      polyline.latlngs.push(latLng(lttd, lngtd))

                      if (idx % 12 === 0 || idx === (vesselPreiousRoute.length - 1)) {
                        markers4.push({
                          opacity: 1.0,
                          markerLatLng: latLng(lttd, lngtd),
                          rotationAngle: Number(pItem.truHdg),
                          vslNm: 'N/A',
                          vslCd: pItem.vslCd,
                          voyNo: pItem.voyNo,
                          rteCd: 'N/A',
                          nvgSts: pItem.nvgSts,
                          cntrDtm: pItem.aisRecvDtm,
                          markersIcon: '',
                          spdOvgGrnd: pItem.spdOvgGrnd
                        })
                      }
                    }
                  }
                }
              }
          }) // privious data FOR문 end

          // 선박 POD에 도착했을때, 선박 위치 표시
          // 정렬
          sortArr.sort((first, second) => {
            return Number(second.aisRecvDtm) - Number(first.aisRecvDtm)
          })

          if (Number(item.eta) < Number(now) && sortArr.length > 0) {
            vesselPreiousRoute.forEach((pItem, idx) => {
              const lttd = Number(pItem.lttd)
              const lngtd = Number(pItem.lngtd)

              if (sortArr[0].aisRecvDtm === pItem.aisRecvDtm) {
                this.markers3.push({
                  opacity: 1.0,
                  markerLatLng: latLng(lttd, lngtd),
                  rotationAngle: Number(pItem.truHdg),
                  vslNm: 'N/A',
                  vslCd: pItem.vslCd,
                  voyNo: 'N/A',
                  rteCd: 'N/A',
                  nvgSts: pItem.nvgSts,
                  cntrDtm: pItem.aisRecvDtm,
                  markersIcon: ''
                })
              }
            })
          }

          // if (Number(item.eta) > Number(now)) {
          //  let resData
          //  let VesselNextRoute

          // //  console.log('getVesselNextRoute data @@@@@ ', data)

          //  // 다음 경로 구할때, 파라미터 넣기
          //  data.drctn = ['E', 'W', 'N', 'S'].includes(item.voyNo.slice(-1)) ? item.voyNo.slice(-1) : this.markers[index].voyNo.slice(0, 1)

          //  await vslScheduleInfo.getVesselNextRoute(data)
          //   .then(async response => {
          //     console.log('getVesselNextRoute response @@@@@ ', response)

          //   if (response.status === 200) {
          //       resData = response.data
          //       VesselNextRoute = resData.vesselNextRoute
          //        this.polylineN.latlngs = []

          //       if (VesselNextRoute !== undefined && VesselNextRoute.length > 0) {
          //         await VesselNextRoute.forEach(item => {
          //           for (let i = 191; i <= this.markers2.length - 1; i++) {
          //             var ais_dest = item.aisDest

          //             ais_dest = ais_dest.replace(/ /g, '')

          //             if (ais_dest.indexOf('OFF') === -1) {
          //               if (ais_dest.indexOf('_') > -1) {
          //                 ais_dest = ais_dest.replaceAll('_', ' ').substring(3, 6)
          //               } else if (ais_dest.indexOf('-') > -1) {
          //                 ais_dest = ais_dest.replaceAll('-', '')
          //               } else {
          //                 if (ais_dest.indexOf('PUS') > -1) { // 부산 포트이름 특이케이스
          //                   ais_dest = 'PUS'
          //                 } else {
          //                   ais_dest = ais_dest.substring(ais_dest.indexOf('>') + 1, ais_dest.length + 1)
          //                 }
          //               }

          //               if (ais_dest === this.markers2[i].ctrCd) {
          //                 item.aisDest = this.markers2[i].plcCd
          //                 break
          //               }
          //             }
          //           }
          //         })
          //       }
          //       await VesselNextRoute.forEach(item => {
          //         const lttd = item.lttd
          //         const lngtd = item.lngtd
          //         var ais_dest = item.aisDest

          //         if (ais_dest === this.focusVessel[4].aisDest && lngtd >= 45) {
          //           tmp.push(Math.sqrt(Math.pow((this.focusVessel.markerLatLng.lat - lttd), 2) + Math.pow((this.focusVessel.markerLatLng.lng - lngtd), 2)))
          //           this.polylineN.latlngs.push([lttd, lngtd])
          //         }
          //       })
          //     }
          //   })
          //   .catch(error => {
          //     console.log(error)
          //   })

          //   if (VesselNextRoute.length > 0 && this.focusVessel.markerLatLng !== undefined) {
          //     this.minIndex = tmp.indexOf(Math.min.apply(null, tmp))
          //     this.polylineN.latlngs = this.polylineN.latlngs.slice(0, this.minIndex)
          //     this.polylineN.latlngs.push([this.focusVessel.markerLatLng.lat, this.focusVessel.markerLatLng.lng])
          //   }
          // }
        } //화물추적 정보로 AIS 데이터 가공 end

        // 좌표 한번에 넣어줌
        this.polyline.latlngs = polyline.latlngs
        this.markers4 = markers4

        // this.polylineN.latlngs = polylineN.latlngs
      }
    },
    closePopup () {
      this.popupParams = {}
      this.popupComponent = null
      this.popupCallback = ''
      this.$ekmtcCommon.layerClose('.cargo-vessel-location-popup')
    },
    openPopup (compNm, params) {
      this.popupParams = params

      this.popupComponent = compNm
      this.$ekmtcCommon.layerOpen('.cargo-vessel-location-popup')
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/sol.css"
</style>
